<template>
  <div></div>
</template>

<script>
export default {
  name: 'BlankComponent',
};
</script>

<style></style>
